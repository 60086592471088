import Vue from "vue";

import "bootstrap";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";

// Install PrimeVue
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
Vue.use(PrimeVue, { ripple: true });
Vue.use(ToastService);

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import "primeflex/primeflex.css";
import Toast from "primevue/toast";
import Calendar from "primevue/calendar";
import Sidebar from 'primevue/sidebar';
import FileUpload from 'primevue/fileupload';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

Vue.component("Calendar", Calendar);
Vue.component("Toast", Toast);
Vue.component("Sidebar", Sidebar);
Vue.component("FileUpload", FileUpload);
Vue.component("Checkbox", Checkbox);
Vue.component("Dialog", Dialog);
Vue.component("Button", Button);



import Swal from "sweetalert2";

Vue.prototype.$Swal = Swal;




import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput);