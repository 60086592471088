import Vue from "vue";
import { HTTP, baseUploadURL, baseURL } from "./axios";
import moment from "moment";

Vue.prototype.$baseUploadURL = baseUploadURL;
Vue.prototype.$baseURL = baseURL;

Vue.prototype.$moment = moment;

Vue.prototype.$http = HTTP;

Vue.prototype.$eventHub = new Vue();


Vue.prototype.$changeRtl = (lang: any) => {
  localStorage.lang = lang;
  const d: any = document.getElementById('bodyX');

  if (d) {

    d.attributes.class.value = lang === 'en' ? 'ltr' : '';
  }
};

if (localStorage.lang) {
  Vue.prototype.$changeRtl(localStorage.lang);
}


Vue.prototype.$textSlice = (text: any, limit = 10) => {
  if (text && text.length > limit) {
    return text.slice(0, limit) + "...";
  } else {
    return text;
  }
};



Vue.prototype.$formatDate = (date: any) => {
  return moment(date).format("DD/MM/YYYY");
};



Vue.prototype.$file2base64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});
