<template>
  <div>
    <div id="carouselExampleCaptions" class="carousel slide">
      <div class="carousel-indicators">
        <button
          v-for="(item, index) in sliderList"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          :data-bs-slide-to="index"
          :class="index == 0 ? 'active' : ''"
          aria-current="true"
          :aria-label="item.name"
        ></button>
      </div>
      <div class="carousel-inner">
        <div
          v-for="(item, index) in sliderList"
          class="carousel-item"
          :class="index == 0 ? 'active' : ''"
        >
          <img
            :src="$baseUploadURL + item.image"
            class="d-block w-100 img-hero"
            alt="عطلات رحلاتكم | Otolat Rahlatcom"
          />
          <div class="carousel-caption d-none d-md-block">
            <div class="w-50 text-center">
              <h5>
                {{ lang == "ar" ? item.header : item.headerEn }}
                <span> {{ lang == "ar" ? item.span : item.spanEn }}</span>
              </h5>
              <p class="m-5">
                {{ lang == "ar" ? item.text : item.textEn }}
              </p>
              <div>
                <a
                  v-if="item.btn1"
                  :href="item.btn1Link"
                  class="btn btn-primary ms-3 btn-slide"
                >
                  {{ lang == "ar" ? item.btn1Text : item.btn1TextEn }}
                </a>
                <a
                  v-if="item.btn2"
                  :href="item.btn2Link"
                  class="btn btn-light btn-slide"
                >
                  {{ lang == "ar" ? item.btn2Text : item.btn2TextEn }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end silder -->

    <!-- start req -->
    <div class="container pt-5 pb-5 req rr">
      <div class="heaad text-center">
        {{ $t("Services we provide") }}
        <h2>
          {{ $t("Requirements with one click") }}
          <span style="color: #ec8f03"> {{ $t("button") }} </span>
        </h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="64"
          height="4"
          viewBox="0 0 64 4"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x2="1"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#fff" />
              <stop offset="1" stop-color="#433771" />
            </linearGradient>
          </defs>
          <rect
            id="Rectangle_756"
            data-name="Rectangle 756"
            width="64"
            height="4"
            fill="url(#linear-gradient)"
          />
        </svg>
      </div>

      <!-- Slider main container -->
      <div ref="newSwiper" class="swiper">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide" v-for="item of servicesList">
            <div
              class="card swip-card"
              style="width: 18rem"
              @click="$router.push('/services?id=' + item.id)"
            >
              <img
                :src="$baseUploadURL + item.image"
                class="card-img-top"
                :alt="lang == 'ar' ? item.name : item.nemaEn"
                style="height: 171px"
              />

              <div>{{ lang == "ar" ? item.name : item.nemaEn }}</div>
            </div>
          </div>
        </div>

        <!-- If we need scrollbar -->
        <div class="swiper-scrollbar"></div>
        <!-- If we need navigation buttons -->
        <div class="swiper-button">
          <div class="line"></div>
          <div class="card-btn">
            <div class="cusom-swiper-button-next">
              <svg
                id="arrow-ios-back"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <rect
                  id="Rectangle_768"
                  data-name="Rectangle 768"
                  width="24"
                  height="24"
                  transform="translate(24) rotate(90)"
                  fill="#9b9a9a"
                  opacity="0"
                />
                <path
                  id="Path_1215"
                  data-name="Path 1215"
                  d="M9.154,19a1,1,0,0,0,.78-.37l4.83-6a1,1,0,0,0,0-1.27l-5-6a1,1,0,0,0-1.54,1.28L12.694,12l-4.32,5.36A1,1,0,0,0,9.154,19Z"
                  transform="translate(1.016)"
                  fill="#9b9a9a"
                />
              </svg>
            </div>
            <div class="cusom-swiper-button-prev">
              <svg
                id="arrow-ios-back"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <rect
                  id="Rectangle_768"
                  data-name="Rectangle 768"
                  width="24"
                  height="24"
                  transform="translate(24) rotate(90)"
                  fill="#9b9a9a"
                  opacity="0"
                />
                <path
                  id="Path_1215"
                  data-name="Path 1215"
                  d="M13.83,19a1,1,0,0,1-.78-.37l-4.83-6a1,1,0,0,1,0-1.27l5-6a1,1,0,0,1,1.54,1.28L10.29,12l4.32,5.36A1,1,0,0,1,13.83,19Z"
                  fill="#9b9a9a"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- start About -->
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-6 col-md-6 text-right">
          <p class="from-text1 mt-5">
            {{ lang == "ar" ? aboutUs.name : aboutUs.nameEn }}
          </p>
          <div class="from-text2">
            {{
              lang == "ar"
                ? getStart(aboutUs.header)
                : getStart(aboutUs.headerEn)
            }}
            <span>
              {{
                lang == "ar"
                  ? getLast(aboutUs.header)
                  : getLast(aboutUs.headerEn)
              }}
            </span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="64"
            height="4"
            viewBox="0 0 64 4"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x2="1"
                y2="1"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stop-color="#fff" />
                <stop offset="1" stop-color="#433771" />
              </linearGradient>
            </defs>
            <rect
              id="Rectangle_755"
              data-name="Rectangle 755"
              width="64"
              height="4"
              fill="url(#linear-gradient)"
            />
          </svg>

          <div class="from-text3">
            {{ lang == "ar" ? aboutUs.top : aboutUs.topEn }}
          </div>
          <div
            class="from-text4"
            v-html="
              $textSlice(lang == 'ar' ? aboutUs.text : aboutUs.textEn, 521)
            "
          ></div>
          <router-link to="/about" class="btn btn-primary mt-4 btn-slide"
            >{{$t("More")}}</router-link
          >
        </div>

        <div class="col-6 col-md-6 text-center">
          <img
            :src="$baseUploadURL + aboutUs.image"
            alt="عطلات رحلاتكم | Otolat Rahlatcom"
          />
        </div>
      </div>
    </div>

    <div class="way">
      <div class="overlay">
        <div class="container pt-5 pb-5 req rr">
          <div class="heaad text-center">
            {{ $t("Why holidays?") }}
            <h2>
              {{ $t("Few reasons to choose") }}
              <br />
              {{ $t("Our visa company") }}
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="64"
              height="4"
              viewBox="0 0 64 4"
            >
              <defs>
                <linearGradient
                  id="linear-gradient"
                  x2="1"
                  y2="1"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stop-color="#fff" />
                  <stop offset="1" stop-color="#fff" />
                </linearGradient>
              </defs>
              <rect
                id="Rectangle_769"
                data-name="Rectangle 769"
                width="64"
                height="4"
                fill="url(#linear-gradient)"
              />
            </svg>

            <div class="whybox">
              <div class="row">
                <div class="col-4">
                  <svg
                    id="ok"
                    xmlns="http://www.w3.org/2000/svg"
                    width="64.5"
                    height="64.5"
                    viewBox="0 0 64.5 64.5"
                  >
                    <path
                      id="Path_1228"
                      data-name="Path 1228"
                      d="M32.25,0A32.25,32.25,0,1,1,0,32.25,32.25,32.25,0,0,1,32.25,0Zm0,62.484A30.234,30.234,0,1,0,2.016,32.25,30.234,30.234,0,0,0,32.25,62.484Z"
                      fill="#ec8f03"
                    />
                    <path
                      id="Path_1229"
                      data-name="Path 1229"
                      d="M29.993,37.454a4.492,4.492,0,0,1-.978-2.8,4.54,4.54,0,0,1,4.535-4.535h7.033q.025-.035.047-.072a3.587,3.587,0,0,0-.094-2.537,12.039,12.039,0,0,1-.8-9.738,5.591,5.591,0,0,1,3.914-3.527c1.793-.435,3.119-.3,3.942.386a2.41,2.41,0,0,1,.806,2.119c-.024.366-.054.736-.085,1.107-.175,2.144-.34,4.169.655,5.9,1.262,2.194,3,3.415,5.017,4.831.613.431,1.247.877,1.893,1.368a14.393,14.393,0,0,1,1.409,1.173h5.993A3.051,3.051,0,0,1,66.3,34.2V53.257a3.051,3.051,0,0,1-3.023,3.07h-6.77c-1.217,1.915-3.6,4.033-8.173,4.032A17.131,17.131,0,0,1,46.08,60.2h-.618a6.554,6.554,0,0,0,1.224-1.96c7.7.867,8.916-4.5,9.035-5.154V49.152a1.008,1.008,0,1,1,2.016,0v4.017a1,1,0,0,1-.01.139,6.715,6.715,0,0,1-.26,1h5.813a1.033,1.033,0,0,0,1.008-1.055V34.2a1.033,1.033,0,0,0-1.008-1.055H58.242v5.7a1.008,1.008,0,1,1-2.016,0V32.967a8.175,8.175,0,0,0-1.567-1.4c-.616-.469-1.235-.9-1.832-1.323-2.119-1.489-4.12-2.895-5.6-5.475-1.308-2.273-1.1-4.82-.916-7.068q.045-.537.082-1.074c.01-.159,0-.366-.091-.445-.171-.143-.821-.3-2.17.029A3.608,3.608,0,0,0,41.6,18.539a10.085,10.085,0,0,0,.7,8,.964.964,0,0,1,.08.156,6.365,6.365,0,0,1,.359,3.439,4.538,4.538,0,0,1,4.418,4.529,4.492,4.492,0,0,1-.978,2.8,4.528,4.528,0,0,1,0,8.519,4.513,4.513,0,0,1-2.016,7.055,4.492,4.492,0,0,1,.978,2.8A4.54,4.54,0,0,1,40.6,60.358H35.566a4.54,4.54,0,0,1-4.535-4.535,4.492,4.492,0,0,1,.978-2.8,4.513,4.513,0,0,1-2.016-7.055,4.528,4.528,0,0,1,0-8.519ZM42.621,32.14H33.55a2.52,2.52,0,0,0,0,5.039h9.07a2.52,2.52,0,0,0,0-5.039Zm-7.055,26.2H40.6a2.52,2.52,0,1,0,0-5.039H35.566a2.52,2.52,0,0,0,0,5.039ZM33.55,51.288h9.07a2.52,2.52,0,0,0,0-5.039H33.55a2.52,2.52,0,0,0,0,5.039Zm-2.016-7.055h13.1a2.52,2.52,0,1,0,0-5.039h-13.1a2.52,2.52,0,1,0,0,5.039Z"
                      transform="translate(-15.41 -6.944)"
                      fill="#ec8f03"
                    />
                    <path
                      id="Path_1230"
                      data-name="Path 1230"
                      d="M42.131,23.493l.394,2.415a1.008,1.008,0,0,1-1.482,1.044l-2-1.108-2,1.108a1.008,1.008,0,0,1-1.482-1.044l.394-2.415-1.661-1.7a1.008,1.008,0,0,1,.569-1.7l2.254-.345,1.017-2.167a1.008,1.008,0,0,1,1.825,0l1.017,2.167,2.254.345a1.008,1.008,0,0,1,.569,1.7Zm-2-1.835a1.008,1.008,0,0,1-.76-.568l-.334-.71-.334.711a1.008,1.008,0,0,1-.76.567l-.847.13.646.662a1.008,1.008,0,0,1,.273.867l-.142.868.675-.373a1.007,1.007,0,0,1,.975,0l.675.373-.142-.869a1.008,1.008,0,0,1,.273-.866l.647-.662Z"
                      transform="translate(3.289 -8.433)"
                      fill="#ec8f03"
                    />
                  </svg>

                  <h3>
                    {{ $t("Ease of application") }}
                  </h3>
                  <p>
                    {{
                      $t(
                        "The importance of choosing marketing phrases. This is due to the increase in the number of restaurants, and it has become a good place to spend time, enjoy, and eat in"
                      )
                    }}
                  </p>
                </div>
                <div class="col-4">
                  <svg
                    id="checkmark-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    width="61.15"
                    height="61.308"
                    viewBox="0 0 61.15 61.308"
                  >
                    <path
                      id="Path_1224"
                      data-name="Path 1224"
                      d="M13.25,22.148A3.078,3.078,0,0,0,8.9,26.5l9.2,9.2a2.975,2.975,0,0,0,4.384-.153L43.935,11.021a3.065,3.065,0,0,0-4.6-4.046L20.27,29.046Z"
                      transform="translate(10.226 6.329)"
                      fill="#ec8f03"
                    />
                    <path
                      id="Path_1225"
                      data-name="Path 1225"
                      d="M60.136,29.589a3.065,3.065,0,0,0-3.065,3.065A24.515,24.515,0,1,1,32.547,8.131a26.945,26.945,0,0,1,5.824.674,3.065,3.065,0,1,0,1.441-5.947A32.309,32.309,0,0,0,32.547,2,30.654,30.654,0,1,0,63.2,32.654a3.065,3.065,0,0,0-3.065-3.065Z"
                      transform="translate(-2.052 -2)"
                      fill="#ec8f03"
                    />
                  </svg>

                  <h3>
                    {{ $t("Your papers are ready and complete") }}
                  </h3>
                  <p>
                    {{
                      $t(
                        "The importance of choosing marketing phrases. This is due to the increase in the number of restaurants, and it has become a good place to spend time, enjoy, and eat in"
                      )
                    }}
                  </p>
                </div>
                <div class="col-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="63.783"
                    height="61.308"
                    viewBox="0 0 63.783 61.308"
                  >
                    <g id="comment" transform="translate(0 -9.934)">
                      <path
                        id="Path_1226"
                        data-name="Path 1226"
                        d="M91,189.934v11.212h11.212V189.934H91Zm7.475,7.475H94.737v-3.737h3.737Zm7.475-7.475v11.212h11.212V189.934H105.949Zm7.475,7.475h-3.737v-3.737h3.737Zm7.475-7.475v11.212H132.11V189.934H120.9Zm7.475,7.475h-3.737v-3.737h3.737Z"
                        transform="translate(-79.664 -157.576)"
                        fill="#ec8f03"
                      />
                      <path
                        id="Path_1227"
                        data-name="Path 1227"
                        d="M31.892,9.934C14.306,9.934,0,22.508,0,37.964a26.043,26.043,0,0,0,6.447,16.79l-3.3,16.488,15.545-7.773a35.05,35.05,0,0,0,13.2,2.524c17.585,0,31.892-12.574,31.892-28.03S49.477,9.934,31.892,9.934Zm0,52.322A31.259,31.259,0,0,1,19.369,59.7l-.8-.347L8.312,64.482l2.165-10.825-.633-.7a22.179,22.179,0,0,1-6.107-15c0-13.395,12.63-24.292,28.154-24.292s28.154,10.9,28.154,24.292S47.416,62.256,31.892,62.256Z"
                        transform="translate(0 0)"
                        fill="#ec8f03"
                      />
                    </g>
                  </svg>

                  <h3>
                    {{ $t("Technical support to help") }}
                  </h3>
                  <p>
                    {{
                      $t(
                        "The importance of choosing marketing phrases. This is due to the increase in the number of restaurants, and it has become a good place to spend time, enjoy, and eat in"
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- start req -->
    <div class="container pt-5 pb-5 req rr click">
      <div class="heaad text-center">
        {{ $t("Services we provide") }}
        <h2>
          {{ $t("Requirements with one click") }}
          <span style="color: #ec8f03"> {{ $t("button") }} </span>
        </h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="64"
          height="4"
          viewBox="0 0 64 4"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x2="1"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0" stop-color="#fff" />
              <stop offset="1" stop-color="#433771" />
            </linearGradient>
          </defs>
          <rect
            id="Rectangle_756"
            data-name="Rectangle 756"
            width="64"
            height="4"
            fill="url(#linear-gradient)"
          />
        </svg>
      </div>

      <!-- Slider main container -->
      <div ref="newSwiper2" class="swiper">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide" v-for="item of visaList">
            <div
              class="card swip-card-2"
              @click="$router.push('/requirements/' + item.id)"
            >
              <img
                :src="$baseUploadURL + item.image"
                :alt="lang == 'ar' ? item.name : item.nemaEn"
                style="width: 85px; height: 85px;border-radius: 50%;"
              />

              <button class="btn btn-primary" >
                {{ lang == "ar" ? item.name : item.nemaEn }}
              </button>
            </div>
          </div>
        </div>

        <!-- If we need scrollbar -->
        <div class="swiper-scrollbar2"></div>
        <!-- If we need navigation buttons -->
        <div class="swiper-button">
          <div class="line"></div>
          <div class="card-btn">
            <div class="cusom-swiper-button-next2">
              <svg
                id="arrow-ios-back"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <rect
                  id="Rectangle_768"
                  data-name="Rectangle 768"
                  width="24"
                  height="24"
                  transform="translate(24) rotate(90)"
                  fill="#9b9a9a"
                  opacity="0"
                />
                <path
                  id="Path_1215"
                  data-name="Path 1215"
                  d="M9.154,19a1,1,0,0,0,.78-.37l4.83-6a1,1,0,0,0,0-1.27l-5-6a1,1,0,0,0-1.54,1.28L12.694,12l-4.32,5.36A1,1,0,0,0,9.154,19Z"
                  transform="translate(1.016)"
                  fill="#9b9a9a"
                />
              </svg>
            </div>
            <div class="cusom-swiper-button-prev2">
              <svg
                id="arrow-ios-back"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <rect
                  id="Rectangle_768"
                  data-name="Rectangle 768"
                  width="24"
                  height="24"
                  transform="translate(24) rotate(90)"
                  fill="#9b9a9a"
                  opacity="0"
                />
                <path
                  id="Path_1215"
                  data-name="Path 1215"
                  d="M13.83,19a1,1,0,0,1-.78-.37l-4.83-6a1,1,0,0,1,0-1.27l5-6a1,1,0,0,1,1.54,1.28L10.29,12l4.32,5.36A1,1,0,0,1,13.83,19Z"
                  fill="#9b9a9a"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Navigation, Scrollbar } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
// import "swiper/modules/navigation.min.css";
// import "swiper/modules/scrollbar.min.css";

export default {
  data() {
    return {
      lang: localStorage.lang || "ar",
      servicesList: [],
      visaList: [],
      sliderList: [],
      aboutUs: {
        name: null,
        nameEn: null,
        header: null,
        headerEn: null,
        top: null,
        topEn: null,
        text: null,
        textEn: null,
        image: null,
      },
    };
  },
  methods: {
    getStart(words) {
      if (words) {
        var n = words.lastIndexOf(" ");
        return words.slice(0, n);
      }
    },
    getLast(words) {
      if (words) {
        var n = words.split(" ");
        return n[n.length - 1];
      }
    },
    isTab() {
      var windowWidth = window.innerWidth;

      return windowWidth < 955;
    },
    isMob() {
      var windowWidth = window.innerWidth;

      return windowWidth < 750;
    },
    getData() {
      new Swiper(this.$refs.newSwiper, {
        // configure Swiper to use modules
        // Optional parameters
        loop: true,
        slidesPerView: this.isTab() ? (this.isMob() ? 1 : 2) : 4,
        spaceBetween: 5,
        // centeredSlides: true,
        // freeMode: true,
        dir: "rtl",
        modules: [Navigation, Scrollbar],
        navigation: {
          nextEl: ".cusom-swiper-button-next",
          prevEl: ".cusom-swiper-button-prev",
        },
        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      });

      new Swiper(this.$refs.newSwiper2, {
        // configure Swiper to use modules
        // Optional parameters
        loop: true,
        slidesPerView: this.isTab() ? (this.isMob() ? 1 : 2) : 5,
        spaceBetween: 5,
        // centeredSlides: true,
        // freeMode: true,
        dir: "rtl",
        modules: [Navigation, Scrollbar],
        navigation: {
          nextEl: ".cusom-swiper-button-next2",
          prevEl: ".cusom-swiper-button-prev2",
        },
        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar2",
        },
      });
    },
  },
  mounted() {
    this.getData();

    this.$http.get(`services`).then((response) => {
      this.servicesList = response.data.data;
    });
    this.$http.get(`visa`).then((response) => {
      this.visaList = response.data.data;
    });
    this.$http.get(`aboutUs/1`).then((response) => {
      this.aboutUs = response.data.data;
    });

    this.$http.get(`slider`).then((response) => {
      this.sliderList = response.data.data;
    });
  },
};
</script>
