<template>
  <div>
    <div class="topnav"></div>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <router-link to="/">
        <img
          class="from-logo"
          style="max-height: 90px; height: 90px"
          :src="$baseUploadURL + setting.logo"
          alt=""
        />
      </router-link>

      <div class="container-fluid">
        <div class="collapse navbar-collapse from-navbar" id="navbarNav">
          <ul class="navbar-nav" style="margin: 0 auto">
            <li class="nav-item from-topnav">
              <router-link to="/" class="nav-link from-nav">
                <img
                  src="../assets/images/visa.png"
                  alt=""
                  style="width: 24px"
                />
                {{ $t("Visas") }}</router-link
              >
            </li>
            <li class="nav-item from-topnav">
              <router-link to="/services" class="nav-link from-nav">
                <img
                  src="../assets/images/hotels.png"
                  alt=""
                  style="width: 24px"
                />
                {{ $t("Hotels") }}</router-link
              >
            </li>
            <li class="nav-item from-topnav">
              <router-link to="/services" class="nav-link from-nav">
                <img
                  src="../assets/images/flights.png"
                  alt=""
                  style="width: 24px"
                />
                {{ $t("airline") }}</router-link
              >
            </li>
            <li class="nav-item from-topnav">
              <router-link to="/course" class="nav-link from-nav">
                <img
                  src="../assets/images/course.png"
                  alt=""
                  style="width: 24px"
                />
                {{ $t("Institutes") }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="from-nav">
          <!-- <button
            
            type="button"
            v-if="lang == 'ar'"
            @click="changeLang('en')"
          >
            EN
          </button>
          <button
            
            type="button"
            v-else
            @click="changeLang('ar')"
          >
            AR
          </button> -->
          <router-link :to="user ? '/updateProfile/' + userId : '/auth/login'">
            <i class="pi pi-user ml-3"></i>
          </router-link>
          <i class="pi pi-bars" @click="visibleLeft = true"></i>
        </div>
      </div>
    </nav>

    <Sidebar
      :visible.sync="visibleLeft"
      :baseZIndex="1000"
      :position="lang == 'ar' ? 'left' : 'right'"
    >
      <ul class="sideul">
        <li class="nav-item from-topnav">
          <router-link to="/" class="nav-link from-nav">
            <img
              src="../assets/images/visa.png"
              alt=""
              style="width: 24px; margin-left: 15px"
            />
            {{ $t("Visas") }}</router-link
          >
        </li>
        <li class="nav-item from-topnav">
          <router-link to="/services" class="nav-link from-nav">
            <img
              src="../assets/images/hotels.png"
              alt=""
              style="width: 24px; margin-left: 15px"
            />
            {{ $t("Hotels") }}</router-link
          >
        </li>
        <li class="nav-item from-topnav">
          <router-link to="/services" class="nav-link from-nav">
            <img
              src="../assets/images/flights.png"
              alt=""
              style="width: 24px; margin-left: 15px"
            />
            {{ $t("airline") }}</router-link
          >
        </li>
        <li class="nav-item from-topnav">
          <router-link to="/" class="nav-link from-nav">
            <img
              src="../assets/images/course.png"
              alt=""
              style="width: 24px; margin-left: 15px"
            />
            {{ $t("Institutes") }}</router-link
          >
        </li>
      </ul>
      <hr />
      <ul class="sideul">
        <li class="nav-item from-topnav">
          <router-link
            to="/trips"
            class="nav-link from-nav"
            :class="home == 'trips' ? 'active' : ''"
            >{{ $t("trips") }}</router-link
          >
        </li>
        <li class="nav-item from-topnav">
          <router-link
            to="/services"
            class="nav-link from-nav"
            :class="home == 'services' ? 'active' : ''"
            >{{ $t("Services") }}</router-link
          >
        </li>

        <li class="nav-item from-topnav">
          <router-link
            :class="home == 'about' ? 'active' : ''"
            to="/about"
            class="nav-link from-nav"
          >
            {{ $t("who are we") }}
          </router-link>
        </li>
        <li class="nav-item from-topnav">
          <router-link
            to="/connectUs"
            class="nav-link from-nav"
            :class="home == 'connectUs' ? 'active' : ''"
            >{{ $t("call us") }}</router-link
          >
        </li>
        <li class="nav-item from-topnav">
          <router-link
            :class="home == 'termsAndConditions' ? 'active' : ''"
            to="/termsAndConditions"
            class="nav-link from-nav"
          >
            {{ $t("Terms and Conditions") }}
          </router-link>
        </li>
        <li class="nav-item from-topnav">
          <router-link
            :class="home == 'orderTracking' ? 'active' : ''"
            to="/orderTracking"
            class="nav-link from-nav"
          >
            {{ $t("Track my order") }}
          </router-link>
        </li>

        <li class="nav-item from-topnav" v-if="!user">
          <router-link to="/auth/register" class="nav-link from-nav">{{
            $t("recording")
          }}</router-link>
        </li>
        <li class="nav-item from-topnav" v-if="!user">
          <router-link to="/auth/login" class="nav-link from-nav">{{
            $t("entry")
          }}</router-link>
        </li>

        <li class="nav-item from-topnav" v-if="user">
          <router-link
            :to="'/updateProfile/' + userId"
            class="nav-link from-nav"
          >
            {{ $t("Edit profile") }}</router-link
          >
        </li>
        <li class="nav-item from-topnav" v-if="user">
          <a class="nav-link from-nav" @click="logout()">
            {{ $t("Log out") }}</a
          >
        </li>
      </ul>

      <button
        class="btn-lang"
        type="button"
        v-if="lang == 'ar'"
        @click="changeLang('en')"
      >
        <img
          src="../assets/images/en.svg"
          style="width: 20px; border-radius: 50%"
          alt=""
        />
        English
      </button>
      <button class="btn-lang" type="button" v-else @click="changeLang('ar')">
        <img
          src="../assets/images/ar.svg"
          style="width: 20px; border-radius: 50%"
          alt=""
        />

        العربيه
      </button>
    </Sidebar>
  </div>
</template>
<script>
export default {
  name: "app-header",
  data() {
    return {
      visibleLeft: false,
      lang: localStorage.lang || "ar",
      home: "",
      user: localStorage.otolatrahlatcom ? true : false,
      userId: localStorage.otolatrahlatcom
        ? JSON.parse(localStorage.otolatrahlatcom).id
        : null,
      setting: {
        logo: null,
      },
    };
  },
  methods: {
    changeLang(val) {
      localStorage.lang = val;
      this.$root.$i18n.locale = val;
      this.lang = val;

      this.$changeRtl(val);

      location.reload();
    },
    logout() {
      delete localStorage.otolatrahlatcom;
      location.reload();
    },
    xxx() {
      var currentLocation = window.location.href;

      if (currentLocation.includes("/services")) {
        this.home = "services";
      } else if (currentLocation.includes("/about")) {
        this.home = "about";
      } else if (currentLocation.includes("/connectUs")) {
        this.home = "connectUs";
      } else if (currentLocation.includes("/trips")) {
        this.home = "trips";
      } else if (currentLocation.includes("/termsAndConditions")) {
        this.home = "termsAndConditions";
      } else if (currentLocation.includes("/orderTracking")) {
        this.home = "orderTracking";
      } else if (currentLocation.includes("/auth")) {
        this.home = "auth";
      } else {
        this.home = "";
      }
      // console.log(this.home); trips
    },
  },
  watch: {
    $route(to, from) {
      this.xxx();
    },
  },
  created() {
    this.$http.get(`home/1`).then((response) => {
      this.setting = response.data.data;
    });
  },
};
</script>
