import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./helpers";
import "./assets/css/style.css";
import "./assets/css/styleEn.css";
import i18n from './i18n/i18n';

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
