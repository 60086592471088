import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Layout from "../layouts/layout.vue";
import LayoutNoFooter from "../layouts/layout-nofooter.vue";

import HomeView from "../views/HomeView.vue";
Vue.use(VueRouter);

const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.otolatrahlatcom) {
    next();
    return;
  }
  next("/auth/login");
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.otolatrahlatcom) {
    // console.log("from", from);
    Vue.prototype.$fromPath = from.fullPath;
    next();
    return;
  }
  next("/");
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "",
        name: "Home",
        component: HomeView,
      },
      {
        path: "/about",
        name: "about",
        component: () => import("../views/pages/about.vue"),
      },

      {
        path: "/connectUs",
        name: "connectUs",
        component: () => import("../views/pages/connectUs.vue"),
      },

      {
        path: "/termsAndConditions",
        name: "termsAndConditions",
        component: () => import("../views/pages/termsAndConditions.vue"),
      },

      // {
      //   path: "/orderTracking",
      //   name: "orderTracking",
      //   component: () => import("../views/pages/orderTracking.vue"),
      // },

      {
        path: "/orderTracking",
        name: "orderTracking",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/pages/orderTracking.vue"),
      },

      {
        path: "/allTrips",
        name: "allTrips",
        component: () => import("../views/trips/allTrips.vue"),
      },

      {
        path: "/trips",
        name: "trips",
        component: () => import("../views/trips/trips.vue"),
      },

      {
        path: "/onetrip/:id",
        name: "onetrip",
        component: () => import("../views/trips/onetrip.vue"),
      },

      {
        path: "/services",
        name: "services",
        component: () => import("../views/services/services.vue"),
      },

      {
        path: "/requirements/:id",
        name: "requirements",
        component: () => import("../views/visas/requirements.vue"),
      },

      {
        path: "/reguestvisa/:id",
        name: "reguestvisa",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/visas/reguestvisa.vue"),
      },

      {
        path: "/cart/:id",
        name: "cart",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/visas/cart.vue"),
      },

      {
        path: "/course",
        name: "course",
        component: () => import("../views/course/HomeView.vue"),
      },

      {
        path: "/courserequirements/:id",
        name: "courserequirements",
        component: () => import("../views/course/requirements.vue"),
      },

      {
        path: "/reguestcourse/:id",
        name: "reguestcourse",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/course/reguestcourse.vue"),
      },

      {
        path: "/coursecert/:id",
        name: "coursecert",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/course/coursecert.vue"),
      },

      {
        path: "/updateProfile/:id",
        name: "updateProfile",

        beforeEnter: ifAuthenticated,
        component: () => import("../views/auth/updateProfile.vue"),
      },
    ],
  },
  {
    path: "/auth/",
    name: "LayoutNoFooter",
    component: LayoutNoFooter,
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: "register",
        name: "register",
        component: () => import("../views/auth/register.vue"),
      },
      {
        path: "login",
        name: "login",
        component: () => import("../views/auth/login.vue"),
      },
      {
        path: "forgotPassword",
        name: "forgotPassword",
        component: () => import("../views/auth/forgotPassword.vue"),
      },
      {
        path: "changePassword",
        name: "changePassword",
        component: () => import("../views/auth/changePassword.vue"),
      },
    ],
  },

  {
    path: "/done/:id",
    name: "done",
    component: () => import("../views/visas/done.vue"),
  },
  {
    path: "/done_course/:id",
    name: "done_course",
    component: () => import("../views/course/done.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
