<template>
  <footer>
    <div class="container">
      <div class="footer-box">
        <div class="row">
          <div class="col-3 one">
            <img
              :src="$baseUploadURL + setting.footerLogo"
              alt=""
              style="width: 84%"
            />
          </div>
          <div class="col-3">
            {{ $t("address") }}
            <p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g id="pin" transform="translate(-0.496 -0.122)">
                    <rect
                      id="Rectangle_788"
                      data-name="Rectangle 788"
                      width="19"
                      height="19"
                      transform="translate(0.496 0.122)"
                      fill="#fff"
                      opacity="0"
                    />
                    <path
                      id="Path_1234"
                      data-name="Path 1234"
                      d="M10.5,2A6.5,6.5,0,0,0,4,8.439c0,4.455,5.731,9.414,5.975,9.625a.813.813,0,0,0,1.057,0c.285-.211,5.975-5.17,5.975-9.625A6.5,6.5,0,0,0,10.5,2Zm0,14.349c-1.358-1.293-4.878-4.878-4.878-7.91a4.878,4.878,0,1,1,9.756,0C15.381,11.447,11.861,15.056,10.5,16.349Z"
                      transform="translate(-0.748 -0.374)"
                      fill="#fff"
                    />
                    <path
                      id="Path_1235"
                      data-name="Path 1235"
                      d="M11.345,6a2.845,2.845,0,1,0,2.845,2.845A2.845,2.845,0,0,0,11.345,6Zm0,4.065a1.219,1.219,0,1,1,1.219-1.219A1.219,1.219,0,0,1,11.345,10.065Z"
                      transform="translate(-1.59 -1.122)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </span>
              {{ setting.address }}
            </p>
          </div>
          <div class="col-3">
            {{ $t("phone number") }}
            <p>
              <span>
                <svg
                  id="phone-missed"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <rect
                    id="Rectangle_787"
                    data-name="Rectangle 787"
                    width="18"
                    height="18"
                    fill="#fff"
                    opacity="0"
                  />
                  <path
                    id="Path_1232"
                    data-name="Path 1232"
                    d="M2.046,13.218a3.326,3.326,0,0,1,.146-.559.766.766,0,0,1,.552-.5l4.6-1.05a.766.766,0,0,1,.7.2c.107.1.115.107.613,1.057A7.663,7.663,0,0,0,12.4,8.621c-.981-.49-.989-.49-1.088-.605a.766.766,0,0,1-.2-.7l1.05-4.544a.766.766,0,0,1,.5-.552,2.9,2.9,0,0,1,.552-.138A3.019,3.019,0,0,1,13.8,2a3.525,3.525,0,0,1,3.525,3.525,11.816,11.816,0,0,1-11.8,11.8A3.525,3.525,0,0,1,2,13.8,3.655,3.655,0,0,1,2.046,13.218Zm3.479,2.575A10.276,10.276,0,0,0,15.793,5.525,2,2,0,0,0,13.8,3.533h-.253l-.82,3.556.421.222c.667.345,1.149.6.9,1.211a9.08,9.08,0,0,1-5.5,5.525c-.644.261-.9-.222-1.241-.889l-.222-.421-3.548.812V13.8A2,2,0,0,0,5.525,15.793Z"
                    transform="translate(-0.858 -0.467)"
                    fill="#fff"
                  />
                </svg>
              </span>
              {{ setting.phone }}
            </p>
          </div>
          <div class="col-3">
            {{ $t("E-mail") }}
            <p>
              <span>
                <svg
                  id="email"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <rect
                    id="Rectangle_786"
                    data-name="Rectangle 786"
                    width="18"
                    height="18"
                    fill="#fff"
                    opacity="0"
                  />
                  <path
                    id="Path_1231"
                    data-name="Path 1231"
                    d="M14.588,4H4.221A2.221,2.221,0,0,0,2,6.221v7.4a2.221,2.221,0,0,0,2.221,2.221H14.588a2.221,2.221,0,0,0,2.221-2.221v-7.4A2.221,2.221,0,0,0,14.588,4Zm-.5,1.481L9.4,9,4.718,5.481Zm.5,8.886H4.221a.74.74,0,0,1-.74-.74V6.407l5.48,4.11a.741.741,0,0,0,.889,0l5.48-4.11v7.22A.74.74,0,0,1,14.588,14.367Z"
                    transform="translate(-0.519 -1.038)"
                    fill="#fff"
                  />
                </svg>
              </span>
              {{ setting.email }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-2 box box-left">
            <h4>{{ $t("Links") }}</h4>
            <p>
              <router-link to="/">
                {{ $t("Visas") }}
              </router-link>
              <router-link to="/services">
                {{ $t("Services") }}
              </router-link>
              <router-link to="/about">
                {{ $t("who are we") }}
              </router-link>
              <router-link to="/orderTracking">
                {{ $t("Track my order") }}
              </router-link>
              <router-link to="/connectUs">
                {{ $t("call us") }}
              </router-link>
            </p>
          </div>
          <div class="col-md-4 box text-center">
            <h4>{{ $t("times of work") }}</h4>
            <div
              v-html="lang == 'ar' ? setting.workTimes : setting.workTimesEn"
            ></div>
          </div>
          <div class="col-md-3 text-center icons">
            <a :href="setting.facebook" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="38"
                viewBox="0 0 39 38"
              >
                <g
                  id="Group_67122"
                  data-name="Group 67122"
                  transform="translate(-143 -3058)"
                >
                  <ellipse
                    id="Ellipse_15"
                    data-name="Ellipse 15"
                    cx="19.5"
                    cy="19"
                    rx="19.5"
                    ry="19"
                    transform="translate(143 3058)"
                    fill="#fff"
                  />
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M15.062,3.4a.4.4,0,0,0-.412-.4h-2.06A3.876,3.876,0,0,0,8.472,6.565v2.14H6.412A.4.4,0,0,0,6,9.1v2.061a.4.4,0,0,0,.412.4h2.06v5.31a.4.4,0,0,0,.412.4h2.472a.4.4,0,0,0,.412-.4v-5.31h2.159a.41.41,0,0,0,.4-.293L14.922,9.2a.384.384,0,0,0-.07-.343.418.418,0,0,0-.325-.156h-2.76V6.565a.813.813,0,0,1,.824-.713h2.06a.4.4,0,0,0,.412-.4Z"
                    transform="translate(152 3067.006)"
                    fill="#433771"
                  />
                </g>
              </svg>
            </a>
            <a :href="setting.twitter" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="38"
                viewBox="0 0 39 38"
              >
                <g
                  id="Group_67123"
                  data-name="Group 67123"
                  transform="translate(-102 -3058)"
                >
                  <ellipse
                    id="Ellipse_14"
                    data-name="Ellipse 14"
                    cx="19.5"
                    cy="19"
                    rx="19.5"
                    ry="19"
                    transform="translate(102 3058)"
                    fill="#fff"
                  />
                  <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M7.594,18.469A10.01,10.01,0,0,0,17.939,8.522a7.316,7.316,0,0,0,1.338-2.568.4.4,0,0,0-.561-.461,1.7,1.7,0,0,1-1.953-.344A3.518,3.518,0,0,0,11.718,5a3.735,3.735,0,0,0-1.04,3.527A7.885,7.885,0,0,1,3.9,5.293a.389.389,0,0,0-.678.217A8.753,8.753,0,0,0,7.386,14.6,6.086,6.086,0,0,1,3.344,16.66a.407.407,0,0,0-.127.76,9.947,9.947,0,0,0,4.377,1.049"
                    transform="translate(109.996 3065.997)"
                    fill="#433771"
                  />
                </g>
              </svg>
            </a>
            <a :href="setting.linkedin" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="38"
                viewBox="0 0 39 38"
              >
                <g
                  id="Group_67124"
                  data-name="Group 67124"
                  transform="translate(-59 -3058)"
                >
                  <ellipse
                    id="Ellipse_13"
                    data-name="Ellipse 13"
                    cx="19.5"
                    cy="19"
                    rx="19.5"
                    ry="19"
                    transform="translate(59 3058)"
                    fill="#fff"
                  />
                  <g
                    id="Group_67121"
                    data-name="Group 67121"
                    transform="translate(-1286.303 -44.354)"
                  >
                    <path
                      id="Path_4"
                      data-name="Path 4"
                      d="M14.206,8.4A4.89,4.89,0,0,0,9.3,13.281v4.932a.755.755,0,0,0,.755.755h1.761a.755.755,0,0,0,.755-.755V13.281a1.627,1.627,0,0,1,1.8-1.619,1.677,1.677,0,0,1,1.468,1.677v4.873a.755.755,0,0,0,.755.755h1.761a.755.755,0,0,0,.755-.755V13.281A4.89,4.89,0,0,0,14.206,8.4Z"
                      transform="translate(1353.287 3109.483)"
                      fill="#433771"
                    />
                    <rect
                      id="Rectangle_6"
                      data-name="Rectangle 6"
                      width="3.774"
                      height="9.813"
                      rx="0.9"
                      transform="translate(1357.303 3118.638)"
                      fill="#433771"
                    />
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="1.887"
                      cy="1.887"
                      r="1.887"
                      transform="translate(1357.303 3113.354)"
                      fill="#433771"
                    />
                  </g>
                </g>
              </svg>
            </a>
            <a :href="setting.youtube" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="38"
                viewBox="0 0 39 38"
              >
                <g
                  id="Group_67125"
                  data-name="Group 67125"
                  transform="translate(-16 -3058)"
                >
                  <ellipse
                    id="Ellipse_10"
                    data-name="Ellipse 10"
                    cx="19.5"
                    cy="19"
                    rx="19.5"
                    ry="19"
                    transform="translate(16 3058)"
                    fill="#fff"
                  />
                  <path
                    id="youtube_2_"
                    data-name="youtube (2)"
                    d="M20.829,8.975a7.108,7.108,0,0,0-.82-3.573,2.657,2.657,0,0,0-1.55-.865,73.573,73.573,0,0,0-7.544-.268,73.652,73.652,0,0,0-7.517.26,2.628,2.628,0,0,0-1.316.64,4.345,4.345,0,0,0-.991,2.985,40.1,40.1,0,0,0,0,5.606,7.954,7.954,0,0,0,.27,1.73A2.681,2.681,0,0,0,2,16.665a2.615,2.615,0,0,0,1.343.675,42.411,42.411,0,0,0,5.859.285c3.155.043,5.922,0,9.194-.242a2.636,2.636,0,0,0,1.379-.675,2.141,2.141,0,0,0,.55-.865,8.815,8.815,0,0,0,.469-2.941C20.829,12.418,20.829,9.494,20.829,8.975ZM8.877,13.421V8.066l5.336,2.69C12.716,11.553,10.743,12.452,8.877,13.421Z"
                    transform="translate(24.868 3066.811)"
                    fill="#433771"
                  />
                </g>
              </svg>
            </a>
          </div>
          <div class="col-md-3 text-center" v-html="setting.map"></div>
        </div>

        <div
          class="text-center bt-3 pb-3"
          v-html="lang == 'ar' ? setting.copyrights : setting.copyrightsEn"
        ></div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      lang: localStorage.lang || "ar",

      setting: {
        footerLogo: null,
        address: null,
        phone: null,
        email: null,
        workTimes: null,
        facebook: null,
        twitter: null,
        linkedin: null,
        youtube: null,
        map: null,
        copyrights: null,
        workTimesEn: null,
        copyrightsEn: null,
      },
    };
  },
  created() {
    this.$http.get(`home/1`).then((response) => {
      this.setting = response.data.data;
    });
  },
};
</script>
